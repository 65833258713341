<template>
  <div class="home">
    <div>
      <img src="../../../public/images/xqy/低功耗wifi-BW2-B1/images/BW2--B1_01.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BW2-B1/images/BW2--B1_02.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BW2-B1/images/BW2--B1_03.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BW2-B1/images/BW2--B1_04.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BW2-B1/images/BW2--B1_05.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BW2-B1/images/BW2--B1_06.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BW2-B1/images/BW2--B1_07.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BW2-B1/images/BW2--B1_08.png" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BW2-B1/images/BW2--B1_09.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BW2-B1/images/BW2--B1_10.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BW2-B1/images/BW2--B1_11.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BW2-B1/images/BW2--B1_12.png" lazy width="100%" height="100%">
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import elMenu from '../../../src/components/elMenu'
import foot from '../../../src/components/foot'
import navigation from '../../../src/components/navigation'
export default {
  name: "低功耗wifi-BW2--B1",
  components: {
    elMenu, foot, navigation
  },
}
</script>

<style scoped>

</style>